/* eslint-disable camelcase */
/* eslint-disable radix */
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
  Backdrop,
  Container,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
  Select,
  Box,
  Button,
} from '@material-ui/core'
import { ToastContainer, toast } from 'react-toastify'

import { getSorting, stableSort } from '../../../util/TableUtils'
import { renderRoute } from '../../../util/utils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'
import consts from '../../../util/consts'

import DownloadOrcamento from './DownloadOrcamento'
import 'react-toastify/dist/ReactToastify.css'
import { Archive } from '@material-ui/icons'
import Icon from '@mdi/react'
import { mdiReceiptTextOutline } from '@mdi/js'
import DonwloadNFSe from './DownloadNFSe'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 200px)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
    background: '#fff',
    width: 'calc(50% - 64px)',
    height: 'auto',
    padding: '24px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  modalFooter: {
    width: '100%',
    display: 'flex',
    gap: '4px',
    marginTop: '8px',
    justifyContent: 'flex-end',
  },
  formControl: {
    minWidth: 120,
    margin: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

function List(props) {
  const {
    permissions,
    remove,
    headCells,
    rows,
    handleChangeRowsPerPage,
    onMovePage,
    rowsPerPage,
    page,
    totalSize,
    otherIcons,
    otherIconsClick,
    lossReasonId,
  } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('id')
  const [deleteModal, setDeleteModal] = useState(false)
  const [itemEdit, setItemEdit] = useState([])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleModalOpen = item => {
    setItemEdit(item)
    setDeleteModal(true)
  }

  const handleModalClose = () => {
    setItemEdit([])
    setDeleteModal(false)
  }

  const handleChangeLossReason = e => {
    e.preventDefault()
    const { name, value } = e?.target

    setItemEdit({
      ...itemEdit,
      [name]: value,
    })
  }

  const handleDelete = () => {
    if (!itemEdit?.loss_reason_id) {
      return toast.info('Selecione um motivo de cancelamento', {
        position: 'top-right',
      })
    }

    dispatch(remove(itemEdit?.id, itemEdit?.loss_reason_id, handleModalClose))
  }

  const confirmFinance = (link, status) => {
    if (status !== 4) {
      confirm({
        description:
          'O serviço ainda não está finalizado, deseja gerar o pagamento?',
        title: 'Tem certeza?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar',
        dialogProps: {
          fullWidth: true,
        },
      }).then(() => {
        props.history.push(link)
      })
    } else {
      props.history.push(link)
    }
  }

  console.log(permissions)

  return (
    <Paper>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Tabela de Atendimentos"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell padding="checkbox" />
                  {Object.entries(row).map((item, index) => {
                    const except = ['update', 'delete', 'link_update']
                    if (
                      item[0] === headCells[index]?.id &&
                      except.indexOf(item[0]) === -1
                    ) {
                      if (item[0] === 'id') {
                        return (
                          <TableCell
                            key={item[0]}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.id}
                          </TableCell>
                        )
                      }
                      return <TableCell key={item[0]}>{item[1]}</TableCell>
                    }
                  })}
                  <TableCell>
                    {renderRoute([row.update], permissions) && (
                      <IconButton
                        aria-label="edit"
                        component={Link}
                        to={row.link_update}
                      >
                        <PencilIcon />
                      </IconButton>
                    )}

                    {[consts.ORCAMENTO, consts.AGUARDANDO_REALIZACAO]?.includes(
                      row?.statusId
                    ) &&
                      renderRoute([row.delete], permissions) &&
                      row.canRender && (
                        <IconButton onClick={() => handleModalOpen(row)}>
                          <DeleteIcon />
                        </IconButton>
                      )}

                    {![
                      consts.ORCAMENTO,
                      consts.CANCELADO,
                      consts.ARQUIVADO,
                    ].includes(row.statusId) &&
                      (renderRoute([row.finance], permissions) &&
                      !row.paymentCompleted ? (
                        <>
                          {row.hasBilling &&
                            renderRoute(
                              [row.finance, 'read-billing'],
                              permissions
                            ) && (
                              <IconButton
                                aria-label="finance"
                                component={Link}
                                to={`/billing?service_id=${row.hasBilling}`}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          <IconButton
                            aria-label="finance"
                            onClick={() =>
                              confirmFinance(row.link_finance, row.statusId)
                            }
                          >
                            <CreditCardIcon />
                          </IconButton>
                        </>
                      ) : (
                        renderRoute(
                          [row.finance, 'read-billing'],
                          permissions,
                          {
                            all: true,
                          }
                        ) && (
                          <IconButton
                            aria-label="finance"
                            component={Link}
                            to={`/billing?service_id=${row.hasBilling}`}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )
                      ))}

                    {[consts.ORCAMENTO, consts.AGUARDANDO_REALIZACAO]?.includes(
                      row.statusId
                    ) &&
                      renderRoute(['archive-attendances'], permissions) && (
                        <IconButton
                          aria-label="finance"
                          component={Link}
                          to={`/attendance/archive-orcament/${row.id}`}
                        >
                          <Archive />
                        </IconButton>
                      )}

                    {otherIcons?.map((item, index) => {
                      if (row.canRender) {
                        return (
                          <IconButton
                            key={String(index)}
                            aria-label={item.ariaLabel}
                            component={Link}
                            to={item.link(row.id)}
                          >
                            {item.icon}
                          </IconButton>
                        )
                      }
                    })}
                    {/* {otherIconsClick.map((item, index) => {
                      if (row.canRender && item.renderRule(row.statusId)) {
                        return (
                          <IconButton
                            aria-label={item.ariaLabel}
                            key={String(index)}
                            onClick={() => item.onClick(row.id)}
                          >
                            {item.icon}
                          </IconButton>
                        )
                      }
                    })} */}
                    <DownloadOrcamento id={row.id} />

                    {row?.nfseStatusId == 20 &&
                      renderRoute(['archive-attendances'], permissions) && (
                        <DonwloadNFSe
                          nfseCompanyId={row.nfse_company_id}
                          serviceId={row?.id}
                        />
                      )}

                    {![
                      consts.ORCAMENTO,
                      consts.ARQUIVADO,
                      consts.CANCELADO,
                    ]?.includes(row.statusId) &&
                      renderRoute(['archive-attendances'], permissions) &&
                      !row?.nfseStatusId && (
                        <IconButton
                          aria-label="finance"
                          component={Link}
                          to={`/attendance/eletronic-invoice/${row.id}`}
                        >
                          <Icon
                            path={mdiReceiptTextOutline}
                            size={1}
                            title="Gerar NFse"
                          />
                        </IconButton>
                      )}

                    {![
                      consts.ORCAMENTO,
                      consts.ARQUIVADO,
                      consts.CANCELADO,
                    ]?.includes(row.statusId) &&
                      renderRoute(['archive-attendances'], permissions) &&
                      row?.nfseStatusId == 30 && (
                        <IconButton
                          aria-label="finance"
                          component={Link}
                          to={`/attendance/eletronic-invoice/${row.id}`}
                        >
                          <Icon
                            path={mdiReceiptTextOutline}
                            size={1}
                            title="Reenviar NFse"
                          />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={parseInt(rowsPerPage)}
        rows={totalSize}
        page={page}
        handleChangePage={onMovePage}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={deleteModal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <Container className={classes.modalBody}>
            <Typography variant="h6"> Tem certeza? </Typography>

            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Motivo de cancelamento
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // eslint-disable-next-line camelcase
                value={itemEdit?.loss_reason_id || ''}
                onChange={handleChangeLossReason}
                label="Motivo de cancelamento"
                name="loss_reason_id"
                fullWidth
              >
                {lossReasonId?.map(reason => (
                  <MenuItem value={reason?.id} key={reason?.id}>
                    {reason?.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box className={classes.modalFooter}>
              <Button onClick={handleModalClose}>Cancelar</Button>
              <Button onClick={handleDelete} color="primary">
                Sim
              </Button>
            </Box>
          </Container>
        </Fade>
      </Modal>
      <ToastContainer />
    </Paper>
  )
}

export default withRouter(List)
